import React from "react";

import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "./SignInButton";


/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
export const PageLayout = (props) => {
    const isAuthenticated = useIsAuthenticated();

    return (
        <>
            
                
                { isAuthenticated ? 
                
                null 
                : 
                <div>
                <div className='logo'><img src="favicon.png" alt=""></img></div>
            <h1 className="h1">PREDICT</h1>
                <center><SignInButton /></center>
                </div>
                 }
       
            
            <br />
            <br />
            {props.children}
        </>
    );
};