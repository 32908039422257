import React, { useState } from "react";
import './App.css';
import CMCard from './components/CMCard/CMCard';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap'
import { PageLayout } from "./components/PageLayout";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { ProfileData } from "./components/ProfileData";
import { callMsGraph } from "./graph";









function App() {
  
  
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);
  
    const name = accounts[0] && accounts[0].name;
  
    function RequestProfileData() {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };
        
  
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            callMsGraph(response.accessToken).then(response => setGraphData(response));
        }).catch((e) => {
            instance.acquireTokenPopup(request).then((response) => {
                callMsGraph(response.accessToken).then(response => setGraphData(response));
            });
        });
    }
  
    
  

  return (
    <PageLayout>
      <AuthenticatedTemplate>
        <div className="App">
          <div className="heading">
            <div className='logo'><img src="favicon.png" alt=""></img></div>
            <h1 className="h1">PREDICT</h1>
            <br/>
            <h5 className="card-title"><center>Welcome {name}</center></h5>
            <br/>
            <div className="instructions"><em>Select the type of Offer and the Products that Offer would be valid for, along with a Target Audience to receive the Offer</em></div>
            <div className="instructions"><em>and the algorithm will tell you what percentage of the Target Audience is likely to use the Offer on a Transaction.</em></div>
          </div>
          <div className="cards">
            <Container>
              <Row>
                <Col><div className="cmccard"><CMCard /></div></Col>
              </Row>
            </Container>
            {graphData ? 
                <ProfileData graphData={graphData} />
                :
              null

            }
          </div>
        </div>
        
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <p>{RequestProfileData}</p>
      </UnauthenticatedTemplate>
    </PageLayout>
  );


}

export default App;
