import React from "react";
import { useMsal } from "@azure/msal-react";
import "./Auth.css"


function handleLogout(instance) {
    instance.logoutPopup().catch(e => {
        console.error(e);
    });
}

/**
 * Renders a button which, when selected, will open a popup for logout
 */
export const SignOutButton = () => {
    const { instance } = useMsal();

    return (
        <div className="signout"><center><button className="button-26" onClick={() => handleLogout(instance)}>Sign out</button></center></div>
    );
}