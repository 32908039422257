import React, { useState, useEffect } from 'react';
import { Card, Form, Button, Container, Row, Col, Modal } from 'react-bootstrap';
import './CMCard.css';
import { FcAddDatabase, FcDataSheet, FcDatabase } from "react-icons/fc";
// import Select from 'react-select';
import { SignOutButton } from '../SignOutButton';
import { useMsal } from "@azure/msal-react";
import { 
	offerMechanisms,
	targetSegments,
	productSegments,
	predictionSources
} from "../../backend_responses"


export default function CMCard() {

	const { instance, accounts } = useMsal();
	console.log(instance, accounts)

	const email = accounts[0] && accounts[0].username

	console.log(email)


	// Sends comparison audience # 3 and sets the Prediction to the table
	const [predictions4, setPredictions4] = useState([]);
	const [sendTarget4, setSendTarget4] = useState();

	const handleSubmitPredictions4 = (e) => {
		setPredictions4()

		let selectedPrediction = predictionSources.filter(
			function (prediction) {
				return prediction.targeting_segment_id === parseInt(sendTarget4) && prediction.product_segment_id === parseInt(sendProduct) && prediction.cluster_id === parseInt(sendMech) 
			}
		)
		
		if (typeof selectedPrediction !== 'undefined' && selectedPrediction.length === 1) {
			console.log(selectedPrediction[0])
			setPredictions4(selectedPrediction[0]);
		} else if (selectedPrediction.length > 1) {
			selectedPrediction.sort(
				function(a, b) {
					return new Date(b.prediction_completion_dt) - new Date(a.prediction_completion_dt)
				}
			)
			console.log(selectedPrediction[0])
			setPredictions4(selectedPrediction[0]);
		} else {
			console.log(selectedPrediction)
			setPredictions4()
		}
	}


	// Sends comparison audience #2 and sets the Prediction to the table
	const [predictions3, setPredictions3] = useState([]);
	const [sendTarget3, setSendTarget3] = useState();

	const handleSubmitPredictions3 = (e) => {
		setPredictions3()

		let selectedPrediction = predictionSources.filter(
			function (prediction) {
				return prediction.targeting_segment_id === parseInt(sendTarget3) && prediction.product_segment_id === parseInt(sendProduct) && prediction.cluster_id === parseInt(sendMech) 
			}
		)
		
		if (typeof selectedPrediction !== 'undefined' && selectedPrediction.length === 1) {
			console.log(selectedPrediction[0])
			setPredictions3(selectedPrediction[0]);
		} else if (selectedPrediction.length > 1) {
			selectedPrediction.sort(
				function(a, b) {
					return new Date(b.prediction_completion_dt) - new Date(a.prediction_completion_dt)
				}
			)
			console.log(selectedPrediction[0])
			setPredictions3(selectedPrediction[0]);
		} else {
			console.log(selectedPrediction)
			setPredictions3()
		}
	}


	// Sends comparison audience #1 and sets the Prediction to the table
	const [predictions2, setPredictions2] = useState([]);
	const [sendTarget2, setSendTarget2] = useState();

	const handleSubmitPredictions2 = (e) => {
		setPredictions2()

		let selectedPrediction = predictionSources.filter(
			function (prediction) {
				return prediction.targeting_segment_id === parseInt(sendTarget2) && prediction.product_segment_id === parseInt(sendProduct) && prediction.cluster_id === parseInt(sendMech)
			}
		)
		
		if (typeof selectedPrediction !== 'undefined' && selectedPrediction.length === 1) {
			console.log(selectedPrediction[0])
			setPredictions2(selectedPrediction[0]);
		} else if (selectedPrediction.length > 1) {
			selectedPrediction.sort(
				function(a, b) {
					return new Date(b.prediction_completion_dt) - new Date(a.prediction_completion_dt)
				}
			)
			console.log(selectedPrediction[0])
			setPredictions2(selectedPrediction[0]);
		} else {
			console.log(selectedPrediction)
			setPredictions2()
		}
	}


	// Handles initial comparison and hiding compare button 
	const [showComparison, setShowComparison] = useState(false);
	const handleShowComparison = () => setShowComparison(true);
	const [hideCompareBut, setHideCompareBut] = useState(true);
	const handleHideCompareBut = () => setHideCompareBut(false);

	// Handles any comparisons after the first in perpetuity
	const [showComparison2, setShowComparison2] = useState(false);
	const handleShowComparison2 = () => setShowComparison2(true);


	// Sends combination for initial prediction then sets the prediction to table
	const [predictions, setPrediction] = useState([]);
	const [sendMech, setSendMech] = useState();
	const [sendProduct, setSendProduct] = useState();
	const [sendTarget, setSendTarget] = useState();

	const handleSubmit = () => {
		setPrediction()

		let selectedPrediction = predictionSources.filter(
			function (prediction) {
				return prediction.targeting_segment_id === parseInt(sendTarget) && prediction.product_segment_id === parseInt(sendProduct) && prediction.cluster_id === parseInt(sendMech) 
			}
		)
		
		if (typeof selectedPrediction !== 'undefined' && selectedPrediction.length === 1) {
			console.log(selectedPrediction[0]);
			setPrediction(selectedPrediction[0]);
		} else if (selectedPrediction.length > 1) {
			selectedPrediction.sort(
				function(a, b) {
					return new Date(b.prediction_completion_dt) - new Date(a.prediction_completion_dt)
				}
			)
			console.log(selectedPrediction[0]);
			setPrediction(selectedPrediction[0]);
		} else {
			console.log(selectedPrediction);
			setPrediction()
		}
	}


	// Sets Target Audiences to drop down & sets selection to state & disables upon button click
	const [audiences, setAudience] = useState([]);
	// const [audienceSelect, setAudienceSelect] = useState("")
	const [isAudienceDisabled, setIsAudienceDisabled] = useState(false);
	
    useEffect(() => {
		setAudience(targetSegments["target_segments"])
	}, []);


	// Sets Choose Mechanisms to drop down & sets selection to state & disables upon button click
	const [mechanisms, setMechanism] = useState([]);
	// const [selectedMech, setSelectedMech] = useState("")
	const [isDisabled, setIsDisabled] = useState(false);
	
    useEffect(() => {
		setMechanism(offerMechanisms["offer_mechanisms"])
	}, []);


	// Sets Existing Products to drop down & sets selection to state & disables upon selection
	const [products, setProduct] = useState([]);
	// const [productSelect, setProductSelect] = useState("")
	const [isExistingDisabled, setIsExistingDisabled] = useState(false);
	
    useEffect(() => {
		setProduct(productSegments["product_segments"])
	}, []);


	// Next Button
	const [showButton, setShowButton] = useState(false);
	const onClick = () => setShowButton(true);
	const [hideButton, setHideButton] = useState(true);
	// const hide = () => setHideButton(false);
	const [enableButton, setEnableButton] = useState(false)


	// Add Products Button
	const [showAddProd, setShowAddProd] = useState(false);
	const handleShowAddProd = () => setShowAddProd(true);
	const [hideAddProd, setHideAddProd] = useState(true);
	// const hideAPButton = () => setHideAddProd(false);


	// Choose Existing
	const [showChooseExisting, setShowChooseExisting] = useState(false);
	const handleShowChooseExisting = () => setShowChooseExisting(true);
	const [hideSelectProd, setHideSelectProd] = useState(true);
	// const hideProd = () => setHideSelectProd(false);


	// Continue to Audience Button
	const [showAudience, setShowAudience] = useState(false);
	const handleShowAudience = () => setShowAudience(true);
	const [hideContinue, setHideContinue] = useState(true);
	// const hideContinueBut = () => setHideContinue(false);
	const [enableContinueButton, setEnableContinueButton] = useState(false)


	//Show Results and Reset button
	const [showResults, setShowResults] = useState(false);
	const handleShowResults = () => setShowResults(true);
	const [enableDoneButton, setEnableDoneButton] = useState(false)
	const refreshPage = () => {
		window.location.reload();
	}

	const handleClick = () => {
		window.open("https://p3p.azurewebsites.net/feedback.php");
	};


	return (
		<div>
			<Container className="cmcard">
				<Row>
					<Col>
						{showButton ? <div className="empty"></div> : <h6 className="header"><b>What type of offer are you considering?</b></h6>}
						<Card bg="Light" style={{ width: '20rem' }}>
							<Card.Body>
								<Card.Subtitle className="offersubtitle">Offer Mechanism</Card.Subtitle>
								<Form>
									<Form.Group>
										<Form.Select onChange={(e => {
											// setSelectedMech(e.target.value)
											setEnableButton(e.target.value)
											setSendMech(e.currentTarget.value)
										})}>
											<option value="choose" disabled={isDisabled}>Choose</option>
											{mechanisms.map((mechanism) => <option disabled={isDisabled} value={mechanism.cluster_id} key={mechanism.cluster_id} >{mechanism.mech_friendly}</option>)}
										</Form.Select>
									</Form.Group>
								</Form>
							</Card.Body>
						</Card>
						<div className="next">
							{hideButton ? <div className="buttondiv"><button className="button-24" disabled={!enableButton} onClick={() => { onClick(); setHideButton(); setIsDisabled(true) }} >Next</button></div> : null}
						</div>
					</Col>

					<Col>
						{showButton && hideAddProd ?
							<div className="middle">
								<h6 className="header2"><b>What Products Would The Offer Be For?</b></h6>
								<div className="addprodbutton"><button className="button-24" onClick={() => { handleShowAddProd(); setHideAddProd(); }}>Add Products</button></div>
							</div>
							: null}
					</Col>

					{showChooseExisting ? 
                        <Col className="existing">
                            <div className="chooseproducts">
                                {showAudience ? <div className="empty"></div> : <h6 className="header2"><b>What Products Would The Offer Be For?</b></h6>}
                                <Card variant="light" style={{ width: '20rem' }}>
                                    <Card.Body className="existcard">
                                        <Card.Subtitle className="offersubtitle">Existing Products</Card.Subtitle>
                                            <Form className="selector">
                                                <Form.Group>
                                                    <Form.Select value={sendProduct} onChange={(e => {
                                                        // setProductSelect(e.target.value)
                                                        setEnableContinueButton(e.target.value)
                                                        setSendProduct(e.currentTarget.value)
                                                    })}>
                                                        <option value="choose" disabled={isExistingDisabled}>Choose</option>
                                                        {/* <option disabled={isExistingDisabled}>541</option> */}
                                                        {products.map((product) => <option disabled={isExistingDisabled} value={product.product_segment_id} key={product.product_segment_id}>{product.product_segment_name}</option>)}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Form>
                                    </Card.Body>
                                </Card>
                            </div>
                            {hideContinue ? <div className="continue"><button disabled={!enableContinueButton} className="button-24" variant="warning" onClick={() => { handleShowAudience(); setHideContinue(); setIsExistingDisabled(true); }} >Continue</button></div> : null}
                            {showResults ? <div className="reset"><button variant="warning" className="button-24" onClick={refreshPage}> Reset </button></div> : null}
                        </Col> 
                    : null}

					{showAudience ? 
                        <Col className="targetaud">
                            <div className="chooseaud">
                                <Form className="audience">
                                    <h6 className="header"><b>Select An Existing Target Audience</b></h6>
                                    <Card style={{ width: '20rem' }}>
                                        <Card.Body className="existcard">
                                            <Card.Subtitle className="offersubtitle">Target Audience</Card.Subtitle>
                                                <Form.Group>
                                                    <Form.Select value={sendTarget} onChange={(e => {
                                                        // setAudienceSelect(e.target.value);
                                                        setEnableDoneButton(e.target.value);
                                                        setSendTarget(e.currentTarget.value)
                                                    })}>
                                                        <option value="choose" disabled={isAudienceDisabled}>Choose</option>
                                                        {audiences.map((audience) => <option disabled={isAudienceDisabled} value={audience.targeting_segment_id} key={audience.targeting_segment_id}>{audience.targeting_segment_name}</option>)}
                                                    </Form.Select>
                                                </Form.Group>
                                        </Card.Body>
                                    </Card>
                                </Form>
                                {showResults ? <div></div> : <div className="done"><button variant="warning" disabled={!enableDoneButton} className="button-24" onClick={() => { handleShowResults(); handleSubmit(); setIsAudienceDisabled(true); }}>Predict</button></div>}
                            </div>
                        </Col> 
                    : null}


					{hideSelectProd ? 
                        <Modal show={showAddProd} onHide={hideSelectProd} size="xl" aria-labelledby="example-modal-sizes-title-lg">
                            <Modal.Header>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    How Do You Want to Select Products?
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="show-grid">
                                <Container>
                                    <Row>
                                        <Col xs={6} md={4}>
                                            <Card bg="light" style={{ width: '18rem' }}>
                                                <Button variant="light" onClick={() => { handleShowChooseExisting(); setHideSelectProd(); }} >
                                                    <Card.Body>
                                                        <FcDatabase />
                                                        <Card.Title className="existing">Use Existing</Card.Title>
                                                    </Card.Body>
                                                </Button>
                                            </Card>
                                        </Col>
                                        <Col xs={6} md={4}>
                                            <Card bg="light" style={{ width: '18rem' }}>
                                                <Button variant="light" onClick={() => {  }}>
                                                    <Card.Body>
                                                        <FcDataSheet />
                                                        <Card.Title className="new">Upload .CSV File</Card.Title>
                                                    </Card.Body>
                                                </Button>
                                            </Card>
                                        </Col>
                                        <Col xs={6} md={4}>
                                            <Card bg="light" style={{ width: '18rem' }}>
                                                <Button variant="light" onClick={() => {  }}>
                                                    <Card.Body>
                                                        <FcAddDatabase />
                                                        <Card.Title className="new">Create New</Card.Title>
                                                    </Card.Body>
                                                </Button>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Container>
                            </Modal.Body>
                        </Modal> 
                    : null}
				</Row>

				{showResults ?
					<div>
						<div className="resultcard">
							<Col>
                                <div>
                                    <div className="resultheader">
                                        <div className="instructions">Based on the Combination of OFFER MECHANISM, </div>
                                        <div className="instructions">PRODUCT, and TARGET AUDIENCE above, P3P Predicts:</div>
                                    </div>
                                    <div className="carddiv">
                                        <Card className="rcard" style={{ width: '30rem' }}>
                                            <Card.Body>
                                                <Container className="results">
                                                    {predictions ?
                                                        <div>
                                                            <table size="sm">
                                                                <thead>
                                                                    <tr className='takeconfheader'>
                                                                        <th><div className="take">Take Rate</div></th>
                                                                        <th><div className="conf">Confidence</div></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody >
																	<tr key={predictions.id}>
																		<td className="thirteen">{predictions.take_rate}</td>

																		<td>
																			{predictions.condition_confidence === "HIGH" ? <div className="high">HIGH</div> : predictions.condition_confidence === "MEDIUM" ? <div className="medium">MEDIUM</div> : predictions.condition_confidence === "LOW" ? <div className="low">LOW</div> : null}
																		</td>
																	</tr>
                                                                    
                                                                </tbody>
                                                                <tbody>
																	<tr>
																		<td className="taketext">TAKE RATE is the percentage of the population likely to use this Offer</td>
																		<td className="taketext2">CONFIDENCE is how strongly the algorithm indicates the Take Rate</td>
																	</tr>
                                                                </tbody>
																<tfoot className="tfoot">
																	<tr>
																		<td colSpan="2" className="tfoot-subtext">
																			NOTE: These results should be viewed as directional, not definitive. Particularly, Algorithm results should not be used to eliminate audiences, as that affects the Algorithm's rate of ”learning.”
																		</td>
																		
																	</tr>
																</tfoot>
                                                            </table>
                                                        </div>
                                                            :
                                                            <div className="nopredict"><b>This combination of OFFER MECHANISM and PRODUCT requires additional data. We’ll email you when the data has been updated. ​Thanks!</b></div>
                                                    } 

                                                </Container>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
							</Col>
						</div> 
						

						<div>
							<h4 className="addaud">Add Audiences for Comparison</h4>
							<div className="compare">
								<Container>
									<Row>
										<Col>
											<div className="comp1">
												<Card style={{ width: '20rem' }}>
													<Card.Body className="existcard">
														<Card.Subtitle className="existtitle">Comparison Audience #1</Card.Subtitle>
															<Form className="selector">
																<Form.Group>
																	<Form.Select value={sendTarget2} onChange={(e => {
																		setSendTarget2(e.currentTarget.value)
																	})}>
																		<option value="choose">Choose</option>
																		{audiences.map((audience) => <option value={audience.targeting_segment_id} key={audience.targeting_segment_id}>{audience.targeting_segment_name}</option>)}
																	</Form.Select>
																</Form.Group>
															</Form>
													</Card.Body>
												</Card>

											</div>
										</Col>

										<Col>
											<div className="comp2">
												<Card style={{ width: '20rem' }}>
													<Card.Body className="existcard">
														<Card.Subtitle className="existtitle">Comparison Audience #2</Card.Subtitle>
															<Form className="selector">
																<Form.Group>
																	<Form.Select value={sendTarget3} onChange={(e => {
																		setSendTarget3(e.currentTarget.value)
																	})}>
																		<option value="choose">Choose</option>
																		{audiences.map((audience) => <option value={audience.targeting_segment_id} key={audience.targeting_segment_id}>{audience.targeting_segment_name}</option>)}
																	</Form.Select>
																</Form.Group>
															</Form>
													</Card.Body>
												</Card>
											</div>
											{hideCompareBut ?
												<div className="compbut"><button variant="warning" className="button-24" onClick={() => { handleShowComparison(); handleHideCompareBut(); handleSubmitPredictions2(); handleSubmitPredictions3(); handleSubmitPredictions4(); }} >Compare</button></div>
												:
												<div className="change"><button variant="warning" className="button-24" onClick={() => { handleShowComparison2(); handleHideCompareBut(); handleSubmitPredictions2(); handleSubmitPredictions3(); handleSubmitPredictions4(); }} >Change and Compare</button></div>}
										</Col>

										<Col>
											<div className="comp3">
												<Card style={{ width: '20rem' }}>
													<Card.Body className="existcard">
														<Card.Subtitle className="existtitle">Comparison Audience #3</Card.Subtitle>
															<Form className="selector">
																<Form.Group>
																	<Form.Select value={sendTarget4} onChange={(e => {
																		setSendTarget4(e.currentTarget.value)
																	})}>
																		<option value="choose">Choose</option>
																		{audiences.map((audience) => <option value={audience.targeting_segment_id} key={audience.targeting_segment_id}>{audience.targeting_segment_name}</option>)}
																	</Form.Select>
																</Form.Group>
															</Form>
													</Card.Body>
												</Card>
											</div>
										</Col>
									</Row>

									{showComparison2 ? <div className="compresults2">
										<Card className="rcard" style={{ width: '30rem' }}>
											<Card.Body>
												<Container className="results">
													<table size="sm">
														<thead>
															<tr className='takeconfheader'>
																<th><div className="take">Target Audience</div></th>
																<th><div className="take">Target Count</div></th>
																<th><div className="take">Take Rate</div></th>
																<th>
																	<div className="take">
																		<br />
																		Confidence
																		<br />
																	</div>
																</th>
															</tr>
														</thead>
														<tbody >
															<tr>
																{predictions ?
																	<>
																		<td className="compaud">Original</td>
																		<td><div className="thirteen" key={predictions.id}>{predictions.target_count}</div></td>
																		<td><div className="thirteen" key={predictions.id}>{predictions.take_rate}</div></td>
																		<td key={predictions.id}>
																			{predictions.condition_confidence === "HIGH" ? <div className="high">HIGH</div> : predictions.condition_confidence === "MEDIUM" ? <div className="medium">MEDIUM</div> : predictions.condition_confidence === "LOW" ? <div className="low">LOW</div> : null}</td>
																	</>
																	:
																	null
																}
															</tr>
														</tbody>
														<tbody>
															<tr>
																{predictions2 ?
																	<>
																		<td className="compaud">AUDIENCE 1</td>
																		<td><div className="thirteen" key={predictions2.id}>{predictions2.target_count}</div></td>
																		<td><div className="thirteen" key={predictions2.id}>{predictions2.take_rate}</div></td>
																		<td key={predictions2.id}>
																			{predictions2.condition_confidence === "HIGH" ? <div className="high">HIGH</div> : predictions2.condition_confidence === "MEDIUM" ? <div className="medium">MEDIUM</div> : predictions2.condition_confidence === "LOW" ? <div className="low">LOW</div> : null}</td>
																	</>
																	:
																	null
																}
															</tr>
														</tbody>
														<tbody>
															<tr>
																{predictions3 ?
																	<>
																		<td className="compaud">AUDIENCE 2</td>
																		<td><div className="thirteen" key={predictions3.id}>{predictions3.target_count}</div></td>
																		<td><div className="thirteen" key={predictions3.id}>{predictions3.take_rate}</div></td>
																		<td key={predictions3.id}>
																			{predictions3.condition_confidence === "HIGH" ? <div className="high">HIGH</div> : predictions3.condition_confidence === "MEDIUM" ? <div className="medium">MEDIUM</div> : predictions3.condition_confidence === "LOW" ? <div className="low">LOW</div> : null}</td>
																	</>
																	:
																	null
																}
															</tr>
														</tbody>
														<tbody>
															<tr>
																{predictions4 ?
																	<>
																		<td className="compaud">AUDIENCE 3</td>
																		<td><div className="thirteen" key={predictions4.id}>{predictions4.target_count}</div></td>
																		<td><div className="thirteen" key={predictions4.id}>{predictions4.take_rate}</div></td>
																		<td key={predictions4.id}>
																			{predictions4.condition_confidence === "HIGH" ? <div className="high">HIGH</div> : predictions4.condition_confidence === "MEDIUM" ? <div className="medium">MEDIUM</div> : predictions4.condition_confidence === "LOW" ? <div className="low">LOW</div> : null}</td>
																	</>
																	:
																	null
																}
															</tr>
														</tbody>
														<tfoot className="tfoot">
															<tr>
																<td colSpan="4" className="tfoot-subtext">
																	NOTE: These results should be viewed as directional, not definitive. Particularly, Algorithm results should not be used to eliminate audiences, as that affects the Algorithm's rate of ”learning.”
																</td>
															</tr>
														</tfoot>
													</table>
												</Container>
											</Card.Body>
										</Card>
									</div> : showComparison ? <div className="compresults">
										<Card className="rcard" style={{ width: '30rem' }}>
											<Card.Body>
												<Container className="results">
													<table size="sm">
														<thead>
															<tr className='takeconfheader'>
																<th><div className="take">Target Audience</div></th>
																<th><div className="take">Target Count</div></th>
																<th><div className="take">Take Rate</div></th>
																<th>
																	<div className="take">
																		<br />
																		Confidence
																		<br />
																	</div>
																</th>
															</tr>
														</thead>
														<tbody >
															<tr>
																{predictions ?
																	<>
																		<td className="compaud">Original</td>
																		<td><div className="thirteen" key={predictions.id}>{predictions.target_count}</div></td>
																		<td><div className="thirteen" key={predictions.id}>{predictions.take_rate}</div></td>
																		<td key={predictions.id}>
																		{predictions.condition_confidence === "HIGH" ? <div className="high">HIGH</div> : predictions.condition_confidence === "MEDIUM" ? <div className="medium">MEDIUM</div> : predictions.condition_confidence === "LOW" ? <div className="low">LOW</div> : null}</td>
																	</>
																	:
																	null
																}
															</tr>
														</tbody>
														<tbody>
															<tr>
																{predictions2 ?
																	<>
																		<td className="compaud">AUDIENCE 1</td>
																		<td><div className="thirteen" key={predictions2.id}>{predictions2.target_count}</div></td>
																		<td><div className="thirteen" key={predictions2.id}>{predictions2.take_rate}</div></td>
																		<td key={predictions2.id}>
																		{predictions2.condition_confidence === "HIGH" ? <div className="high">HIGH</div> : predictions2.condition_confidence === "MEDIUM" ? <div className="medium">MEDIUM</div> : predictions2.condition_confidence === "LOW" ? <div className="low">LOW</div> : null}</td>
																	</>
																	:
																	null
																}
															</tr>
														</tbody>
														<tbody>
															<tr>
																{predictions3 ?
																	<>
																		<td className="compaud">AUDIENCE 2</td>
																		<td><div className="thirteen" key={predictions3.id}>{predictions3.target_count}</div></td>
																		<td><div className="thirteen" key={predictions3.id}>{predictions3.take_rate}</div></td>
																		<td key={predictions3.id}>
																		{predictions3.condition_confidence === "HIGH" ? <div className="high">HIGH</div> : predictions3.condition_confidence === "MEDIUM" ? <div className="medium">MEDIUM</div> : predictions3.condition_confidence === "LOW" ? <div className="low">LOW</div> : null}</td>
																	</>
																	:
																	null
																}
															</tr>
														</tbody>
														<tbody>
															<tr>
																
																{predictions4 ?
																	<>
																		<td className="compaud">AUDIENCE 3</td>
																		<td><div className="thirteen" key={predictions4.id}>{predictions4.target_count}</div></td>
																		<td><div className="thirteen" key={predictions4.id}>{predictions4.take_rate}</div></td>
																		<td key={predictions4.id}>
																		{predictions4.condition_confidence === "HIGH" ? <div className="high">HIGH</div> : predictions4.condition_confidence === "MEDIUM" ? <div className="medium">MEDIUM</div> : predictions4.condition_confidence === "LOW" ? <div className="low">LOW</div> : null}</td>
																	</>
																	:
																	null
																}
															</tr>
														</tbody>
														<tfoot className="tfoot">
															<tr>
																<td colSpan="4" className="tfoot-subtext">
																	NOTE: These results should be viewed as directional, not definitive. Particularly, Algorithm results should not be used to eliminate audiences, as that affects the Algorithm's rate of ”learning.”
																</td>
																
															</tr>
														</tfoot>
													</table>
												</Container>
											</Card.Body>
										</Card>
									</div>
										:
										null
									}
									
								</Container>
							</div>
						</div>

						<SignOutButton />
						<div className="feedback"><button className="button-26" onClick={handleClick}> Feedback </button></div>


					</div>
					:
					null
				}

			</Container>

		</div>
	)
}